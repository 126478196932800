import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { base } from 'src/app/@core/interfaces/base.interface';
import { ICompany } from 'src/app/@core/interfaces/Company.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    constructor(private http: HttpClient) {
    }


    // for example

    getListCompanies(data: any): Observable<base<ICompany[]>> {
        let httpParams = new HttpParams();
        Object.keys(data).forEach(function (key) {
            if (key && data[key] != undefined && data[key] != null && data[key] != '') {
                httpParams = httpParams.append(key, data[key]);
            }
        });
        return this.http.get<base<ICompany[]>>(environment.citizensApi + "/companies/approved?" + httpParams)
    }

    getApprovedListCompanies(data: any): Observable<base<ICompany[]>> {
        let httpParams = new HttpParams();
        Object.keys(data).forEach(function (key) {
            if (key && data[key] != undefined && data[key] != null && data[key] != '') {
                httpParams = httpParams.append(key, data[key]);
            }
        });
        return this.http.get<base<ICompany[]>>(environment.citizensApi + "/companies/saudi-agents/approved?" + httpParams)
    }

    getApprovedAirlinesList(data: any): Observable<base<ICompany[]>> {
        let httpParams = new HttpParams();
        Object.keys(data).forEach(function (key) {
            if (key && data[key] != undefined && data[key] != null && data[key] != '') {
                httpParams = httpParams.append(key, data[key]);
            }
        });
        return this.http.get<base<ICompany[]>>(environment.citizensApi + "/companies/transporters/approved?" + httpParams)
    }
    getCities(): Observable<any> {
        return this.http.get<any>(environment.citizensApi + "/geo/cities?country_id=20")
    }




}
