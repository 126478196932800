import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}

  getListNews(): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/circulars');
  }
  getNew(id: string): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/circulars/' + id);
  }
}
