import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManasekService {
  constructor(private http: HttpClient) {}

  getListManasek(data:any): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
        if (key && data[key] != undefined && data[key] != null && data[key] != '') {
            httpParams = httpParams.append(key, data[key]);
        }
    });
    return this.http.get<any>(environment.citizensApi + "/rituals?" + httpParams)
  }

  getManasek(id: string): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/rituals/' + id);
  }
}
